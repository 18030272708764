/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3"
  }, _provideComponents(), props.components), {LandingPageCta, MapInfo} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "medicare-and-hearing-aids-what-is-covered",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicare-and-hearing-aids-what-is-covered",
    "aria-label": "medicare and hearing aids what is covered permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicare and hearing aids: what is covered?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the same way that eye health and vision affects our day-to-day lives, our ability to hear and listen carefully has a major impact on the way we experience the world and communicate with one another—it’s an important part of our overall wellbeing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With that in mind, it stands to reason that hearing aids should be covered by health insurance, but that’s not always the case. Here, we’ll fill you in on the kinds of Medicare Advantage plans that cover hearing aids and other ways you can help cover the cost for better hearing health."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "does-medicare-cover-the-cost-of-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#does-medicare-cover-the-cost-of-hearing-aids",
    "aria-label": "does medicare cover the cost of hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Does Medicare cover the cost of hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Original Medicare plans—also referred to as Medicare Part A and Part B—do not currently cover the cost of hearing aids, but legislation to add hearing aids as a benefit has been introduced in the House of Representatives, so that could change in the future. As of today, only Medicare Advantage plans—also known as Medicare Part C—can cover hearing aids, and even then it’s not a guarantee."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In order to better understand what kind of Medicare plans cover hearing aids, it helps to distinguish between the individual parts of Medicare, the kinds of benefits they typically offer, and how you get them:"), "\n", React.createElement(LandingPageCta, {
    copy: "Inquire about hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "medicare-part-a-hospital-insurance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicare-part-a-hospital-insurance",
    "aria-label": "medicare part a hospital insurance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicare Part A (Hospital Insurance)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This helps cover inpatient care in hospitals, skilled nursing facility care, hospice, etc. Part A does not cover the cost of hearing aids or any hearing health services."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*Original Medicare includes Part A and Part B, so all Medicare plans include at least these two parts."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "medicare-part-b-medical-insurance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicare-part-b-medical-insurance",
    "aria-label": "medicare part b medical insurance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicare Part B (Medical Insurance)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This helps cover services from doctors and health care professionals, durable medical equipment such as wheelchairs and walkers, and some preventive care (like screenings, exams, shots/vaccines, etc.)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Part B does cover some costs for ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing-related health conditions"), ", such as hearing tests and, when medically necessary, cochlear implants or bone-anchoring hearing aids (BAHA) which must be surgically installed to improve hearing. Currently, typical hearing aids are not covered."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*Original Medicare includes Part A and Part B. If hearing aids are added as a benefit to Original Medicare in the future, they will likely be added to Medicare Part B."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "medicare-part-c-medicare-advantage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicare-part-c-medicare-advantage",
    "aria-label": "medicare part c medicare advantage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicare Part C (Medicare Advantage)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This describes a Medicare-approved plan from a private company that includes the benefits of Medicare Part A and B (and sometimes part D), but may include additional benefits, like dental, vision, and hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*At this time, these are the only plans that can include hearing aid coverage—and many do—but it depends entirely on each company and plan’s unique benefits."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "medicare-part-d-drug-coverage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicare-part-d-drug-coverage",
    "aria-label": "medicare part d drug coverage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicare Part D (Drug Coverage)"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This helps cover the cost of prescription drugs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "*Patients can opt into this kind of coverage in addition to their original Original Medicare plans or by joining a Medicare Advantage plan with drug coverage. These are only available if you receive your coverage from a private insurance company."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As you dig into the details of the Medicare policies available to you, you may also come across what are called Medigap plans—also known as Medicare Supplemental Insurance. Private insurance companies offer these plans to help cover an individual’s copays, deductibles, or out-of-pocket costs associated with Original Medicare (Plan A and Plan B), but they do not provide additional coverage for hearing aids or health care of any kind."), "\n", React.createElement(LandingPageCta, {
    copy: "Inquire about hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "which-medicare-advantage-plans-include-hearing-aid-coverage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#which-medicare-advantage-plans-include-hearing-aid-coverage",
    "aria-label": "which medicare advantage plans include hearing aid coverage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Which Medicare Advantage plans include hearing aid coverage?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the Centers for Medicare & Medicaid Services, more than 26 million seniors were enrolled in a Medicare Advantage plan (Medicare Part C) in 2022, and according to the Kaiser Foundation, about 93% of those policies do include some coverage for audiology tests and hearing aids. But the amount of coverage varies significantly from plan to plan. A few national insurers known for good hearing aid coverage include Humana, Blue Cross Blue Shield, and United Healthcare."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The easiest way to find out if your plan covers the cost of hearing aids and other hearing health services is to check the “Evidence of Coverage” document which is sent annually by your insurer (usually in the fall). This statement lays out your monthly premiums and deductible, exactly what is covered in your plan, and what your copay/cost share for each device or service will be."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you think you may benefit from access to affordable hearing aids now or in the near future, it may make sense to find a plan that can help you offset the cost, but if that’s not a possibility for you, there are always other ways."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-if-your-medicare-plan-doesnt-cover-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-if-your-medicare-plan-doesnt-cover-hearing-aids",
    "aria-label": "what if your medicare plan doesnt cover hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What if your Medicare plan doesn’t cover hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What happens if you review your Medicare plan and notice that hearing aids are not included as a policy benefit? One option might be to switch plans next year when Open Enrollment rolls around, but the reality is that out-of-pocket costs associated with hearing aids are often still quite high even with a Medicare Advantage plan that covers part of the price."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids can cost anywhere from around $1,500-$7,000 dollars depending on your lifestyle and the severity of your hearing loss, so even footing half the bill can feel like too high of a price. Here are a few other ways to get help with hearing aids beyond Medicare Advantage:"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "veterans-affairs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#veterans-affairs",
    "aria-label": "veterans affairs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Veterans Affairs"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Veterans who meet certain criteria for hearing loss are eligible for hearing aids from the VA."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "medicaid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#medicaid",
    "aria-label": "medicaid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Medicaid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re one of 12.2 million people enrolled in both Medicare and Medicaid, you could qualify. It varies by state, but these are the ones offering at least some level of coverage (as of Dec. 2022): AK, CA, CT, FL, HI, IL, IN, IA, KS, MA, MN, MO, MT, NE, NV, NH, NJ, NM, NY, ND, OH, OR, RI, SD, TX, VE, WI, WY."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "nonprofit-assistance",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#nonprofit-assistance",
    "aria-label": "nonprofit assistance permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Nonprofit assistance"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some organizations exist for the sole purpose of helping people unable to afford hearing aids with the resources to do so. These include but are not limited to: Sertoma, The Foundation for Sight and Sound and The Hearing Aid Project."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "financing-options-for-hearing-care",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#financing-options-for-hearing-care",
    "aria-label": "financing options for hearing care permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Financing options for hearing care"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Companies like ", React.createElement(_components.a, {
    href: "/hearing-aids/financial-assistance/",
    className: "c-md-a"
  }, "hear.com offer flexible payment plans"), " of all kinds to ease the financial burden of investing in a hearing aid. Their knowledgeable experts can help you use your Medicare Advantage insurance to cover as much as possible, and then pay the remaining cost over the course of 12-60 months, depending on what makes most sense for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And if you’re not totally sure you want to make the investment, you can always ", React.createElement(_components.a, {
    href: "https://www.hear.com/d/full_page_questionnaire/",
    className: "c-md-a"
  }, "try a hearing aid free for 45 days"), ", and return it at no cost if it’s not for you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-help-paying-for-hearing-aids-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-help-paying-for-hearing-aids-today",
    "aria-label": "get help paying for hearing aids today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get help paying for hearing aids today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re one of the 1 in 6 Americans suffering from hearing loss in 2022 and you’re feeling ready to improve your hearing and overall wellbeing, don’t wait for better hearing aid coverage from your Medicare plan. Hear.com can help you maximize your current benefits, book an appointment with an audiologist, find the hearing aid that’s right for you, and answer any question you may have about the process, products, financing, and beyond."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To start the process, simply give our friendly experts a call at (786) 520-2456 or find your state below:"), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Inquire about hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
